<script setup>
import { ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { useQuizStore } from "../stores/useQuizStore";
import { checkmarkOutline, closeOutline } from "ionicons/icons";

// Constants
const router = useRouter();
const TIME_LIMIT = 10; // seconds per question
const quizStore = useQuizStore();
const currentQuestion = ref(null);
const checklistFromApi = ref([
  { id: 1, title: "I prodotti sono disposti...", answer: false },
  { id: 2, title: "Le scorte sono adeguatamente...", answer: false },
  { id: 3, title: "Il negozio è pulito...", answer: false },
  { id: 4, title: "Il personale è ben informato...", answer: false },
  { id: 5, title: "Sono stati raccolti e analizzati...", answer: false },
]);
const timeLeft = ref(TIME_LIMIT);
let timer = null;

// Lifecycle
onMounted(() => {
  startQuiz();
});

watch(
  () => currentQuestion.value,
  () => {
    resetTimer();
  }
);

watch(
  () => timeLeft.value,
  (newValue) => {
    if (newValue <= 0) {
      handleAnswer(false); // auto-answer with false if time runs out
    }
  }
);

// Methods
function startQuiz() {
  quizStore.setCurrentQuiz([...checklistFromApi.value]);
  getCurrentQuestion();
}

function getCurrentQuestion() {
  currentQuestion.value = quizStore.currentQuestion;
  resetTimer();
}

function handleAnswer(answer) {
  clearTimeout(timer);
  quizStore.nextQuestion(answer);
  setTimeout(() => {
    getCurrentQuestion();
  }, 1000);
}

function resetTimer() {
  timeLeft.value = TIME_LIMIT;
  clearInterval(timer);
  timer = setInterval(() => {
    if (timeLeft.value > 0) {
      timeLeft.value -= 1;
    }
  }, 1000);
}

function ricomincia() {
  quizStore.restart();
  startQuiz();
}

function inviaQuiz() {
  router.go(-1);
}
</script>

<template>
  <master-layout smallTitle="la tua" bigTitle="Checklist">
    <h6 class="mb-4">
      Valuta pulizia, organizzazione, sicurezza, team e soddisfazione cliente in
      modo efficace e rapido.
    </h6>

    <div
      v-if="
        quizStore.quizState === 'ready' &&
        currentQuestion &&
        currentQuestion.title
      "
      class="mt-4"
    >
      <ion-card class="shadow-4 animate__animated animate__bounceInRight">
        <ion-card-header>
          <div class="w-full d-flex justify-content-between align-items-center">
            <div
              v-for="index in TIME_LIMIT"
              :key="index"
              class="box"
              :class="{
                'bg-success': index <= timeLeft,
                'bg-danger':
                  index <= timeLeft && (timeLeft / TIME_LIMIT) * 100 < 30,
                'bg-transparent': index > timeLeft,
              }"
            ></div>
          </div>
        </ion-card-header>
        <ion-card-content>
          <div
            class="w-full d-flex flex-column justify-content-center align-items-center"
          >
            <span class="mb-2">{{ currentQuestion.title }}</span>

            <div
              class="w-full d-flex justify-content-around align-items-center mt-4"
            >
              <!-- Buttons for answers -->
              <button
                @click="handleAnswer(false)"
                class="p-d button-secondary shadow-2 d-flex justify-content-center align-items-center"
              >
                <ion-icon
                  :icon="closeOutline"
                  style="font-size: 1.5rem"
                ></ion-icon>
                <span class="ml-2">No</span>
              </button>
              <button
                @click="handleAnswer(true)"
                class="p-d button-secondary shadow-2 d-flex justify-content-center align-items-center"
              >
                <ion-icon
                  :icon="checkmarkOutline"
                  style="font-size: 1.5rem"
                ></ion-icon>
                <span class="ml-2">Sì</span>
              </button>
            </div>
          </div>
        </ion-card-content>
      </ion-card>
    </div>

    <div
      v-if="quizStore.quizState === 'finished'"
      class="w-full d-flex justify-content-center align-items-center"
    >
      <button @click="ricomincia" class="p-d button-secondary shadow-2">
        Rispondi nuovamente
      </button>
      <button @click="inviaQuiz" class="p-d button-primary shadow-2 ml-2">
        Invia
      </button>
    </div>
  </master-layout>
</template>

<style scoped>
.box {
  width: 80%;
  height: 15px;
  border-radius: 4px;
  transition: background-color 0.5s ease;
}

.bg-transparent {
  background-color: transparent;
}

.progress-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
}

.bg-success {
  background-color: #28a745;
}

.bg-danger {
  background-color: #ffb941;
}

.bg-transparent {
  background-color: transparent;
}
</style>
